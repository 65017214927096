import React, { Fragment, useRef } from "react";
import Analytics from "../../../common_components/Common/Analytics";
const FaqAccordion = (props) => {
  const contentEl = useRef();
  const { handleToggle, active, faq ,index, type} = props;
  const { que,  ans } = faq;

  const handleClick = () => {
    const Analyticsprops = {
      GA: true,
      title: `FAQ-Events`,
      category: type === 1 ? 'Home Page-Events' : 'Vehicles page-Events',
      action: `FAQ-Events`,
      label: `${que} clicked!`,
  }
  Analytics(Analyticsprops)

    handleToggle(index)
  }
  
  return (
    <Fragment>
      <div className="rc-accordion-card">
        <div className="rc-accordion-header">
          <div
            className={`rc-accordion-toggle p-3 ${
              active === index ? "active" : ""
            }`}
            onClick={() => handleClick()}
          >
            <h3 className="rc-accordion-title">{que}</h3>
            <i className="fa fa-chevron-right rc-accordion-icon"></i>
          </div>
        </div>
        <div
          ref={contentEl}
          className={`rc-collapse ${active === index ? "show" : ""}`}
          style={
            active === index
              ? { height: contentEl.current.scrollHeight }
              : { height: "0px" }
          }
        >
          <div className="rc-accordion-body">
            <p className="mb-0" dangerouslySetInnerHTML={{ __html: ans }}></p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FaqAccordion;
